import {useStaticQuery, graphql} from 'gatsby'
import {css} from '@emotion/react'
import Img from 'gatsby-image'
import {colors} from '../../../constants'
import ZapCircleCut from '../../../images/app/ZapCircleCut.svg'
import ZapBolt from '../../../images/app/ZapBolt.svg'

const OFFSET = 60

const translations = {
    en: {
        title: 'Zap',
        description: 'Wipe your browsing data and tabs with one tap.',
    },
    de: {
        title: 'Zap',
        description: 'Lösche deine Browserdaten und schließe alle Tabs mit einer Berühung.',
    },
    nl: {
        title: 'Zap',
        description: 'Wis uw browsegegevens en tabbladen met één tik.',
    },
}

const ZapPromoSectionDesktop = ({languageCode}) => {
    const text = translations[languageCode]
    const site = useStaticQuery(graphql`
        query ZapPromoSectionDesktopQuery {
            zapPhone: file(relativePath: {eq: "app/ZapPhoneDesktop.png"}) {
                childImageSharp {
                    fixed(height: 289, width: 274, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)
    return (
        <section
            className="zap-promo-section"
            css={css`
                background: ${colors.brandBlueLightest};
                margin-top: ${OFFSET}px;
                padding: 0 2rem;
            `}
        >
            <div
                className="inner-container"
                css={css`
                    display: flex;
                    justify-content: space-between;
                    max-width: 955px;
                    margin: 0 auto;
                    align-items: center;
                    gap: 10px;
                `}
            >
                <div
                    className="zap-section-text"
                    css={css`
                        max-width: 200px;
                        h2,
                        p {
                            color: ${colors.black};
                        }
                        h2 {
                            font-size: 40px;
                            font-weight: 800;
                            margin-bottom: 10px;
                        }
                    `}
                >
                    <h2>{text.title}</h2>
                    <p>{text.description}</p>
                </div>
                <ZapBolt
                    css={css`
                        margin-right: -41px;
                    `}
                />
                <div
                    className="zap-phone"
                    css={css`
                        margin-top: -${OFFSET}px;
                        margin-bottom: -5px;
                    `}
                >
                    <Img ariaHidden fixed={site.zapPhone.childImageSharp.fixed} />
                </div>
                <ZapCircleCut />
            </div>
        </section>
    )
}

export default ZapPromoSectionDesktop
