import {css} from '@emotion/react'
import {colors} from '../../../constants'
import StarRibbon from '../../../images/app/StarRibbon.js'

const translations = {
    en: {
        ribbonNumber: '250k+',
        ribbonText: 'Downloads',
    },
    de: {
        ribbonNumber: '250k+',
        ribbonText: 'Downloads',
    },
    nl: {
        ribbonNumber: '250k+',
        ribbonText: 'Downloads',
    },
}

const RibbonWidget = ({id, languageCode, overrideStyles}) => {
    const text = translations[languageCode]
    return (
        <div
            className="ribbon-widget"
            css={css`
                ${overrideStyles};
            `}
        >
            <h2
                className="ribbon-text-container"
                css={css`
                    text-align: center;
                    transform: rotate(10deg);
                    position: absolute;
                    top: 75px;
                    right: 34px;
                    line-height: 18px;
                    span {
                        text-shadow: 0 2px rgba(0, 0, 0, 0.15);
                        color: ${colors.whisperLight};
                    }
                `}
            >
                <span
                    className="ribbon-number"
                    css={css`
                        font-size: 27px;
                    `}
                >
                    {text.ribbonNumber}
                </span>
                <br />
                <span
                    className="ribbon-text"
                    css={css`
                        font-size: 16px;
                    `}
                >
                    {text.ribbonText}
                </span>
            </h2>
            <StarRibbon id={id} />
        </div>
    )
}

export default RibbonWidget
