import {useStaticQuery, graphql} from 'gatsby'
import {css} from '@emotion/react'
import Img from 'gatsby-image'
import {colors} from '../../../constants'
import ZapCircle from '../../../images/app/ZapCircle.svg'
import ZapBoltMobile from '../../../images/app/ZapBoltMobile.svg'

const translations = {
    en: {
        title: 'Zap',
        description: 'Wipe your browsing data and tabs with one tap.',
    },
    de: {
        title: 'Zap',
        description: 'Lösche deine Browserdaten und schließe alle Tabs mit einer Berühung.',
    },
    nl: {
        title: 'Zap',
        description: 'Wis uw browsegegevens en tabbladen met één tik.',
    },
}

const ZapPromoSectionMobile = ({languageCode}) => {
    const text = translations[languageCode]
    const site = useStaticQuery(graphql`
        query ZapPromoSectionMobileQuery {
            zapPhoneLong: file(relativePath: {eq: "app/ZapPhoneMobile.png"}) {
                childImageSharp {
                    fixed(height: 294, width: 223, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)
    return (
        <section
            className="zap-promo-section"
            css={css`
                background: ${colors.brandBlueLightest};
                padding: 4rem 1rem 0;
                overflow: hidden;
                position: relative;
            `}
        >
            <div
                className="inner-container"
                css={css`
                    display: flex;
                    flex-direction: column;
                    max-width: 955px;
                    margin: 0 auto;
                    align-items: center;
                    gap: 10px;
                `}
            >
                <div
                    className="zap-section-text"
                    css={css`
                        max-width: 300px;
                        text-align: center;
                        z-index: 2;
                        margin: 0 0 3rem;
                        h2,
                        p {
                            color: ${colors.black};
                        }
                        h2 {
                            font-size: 32px;
                            font-weight: 800;
                            margin-bottom: 24px;
                        }
                    `}
                >
                    <h2>{text.title}</h2>
                    <p>{text.description}</p>
                </div>
                <div
                    className="zap-section-images"
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    <ZapBoltMobile
                        css={css`
                            z-index: 2;
                            transform: scale(0.7) translate(10px, 10px);
                            margin: 0 10px;
                        `}
                    />
                    <div
                        className="zap-phone"
                        css={css`
                            position: relative;
                            margin-right: 88px;
                        `}
                    >
                        <Img
                            alt=""
                            fixed={site.zapPhoneLong.childImageSharp.fixed}
                            style={{zIndex: 2, marginBottom: '-4px'}}
                        />
                        <ZapCircle
                            css={css`
                                transform: scale(1.5);
                                position: absolute;
                                bottom: -35px;
                                margin-left: -80px;
                                z-index: 1;
                            `}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ZapPromoSectionMobile
