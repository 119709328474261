import {css} from '@emotion/react'
import IcnLock from '../../../images/app/icn-lock.svg'
import IcnMask from '../../../images/app/icn-mask.svg'
import IcnStar from '../../../images/app/icn-star.svg'
import IcnShield from '../../../images/app/icn-shield.svg'
import {colors} from '../../../constants'

const translations = {
    en: {
        title: 'Instant Privacy',
        description: 'The ultimate mobile command center for private and secure exploration.',
        talkingPoints: [
            {
                text: 'Instant privacy on by default',

                image: <IcnLock />,
            },
            {
                text: 'Strong personal data protection',

                image: <IcnShield />,
            },
            {
                text: 'Maximum anonymity',

                image: <IcnMask />,
            },
            {
                text: 'Best-in-class search results',

                image: <IcnStar />,
            },
        ],
    },
    de: {
        title: 'Sofortige Privatsphäre',
        description: 'Die ultimative mobile Kommandozentrale für sicheres und privates Surfen.',
        talkingPoints: [
            {
                text: 'Sofortige Privatsphäre standardmäßig aktiviert',

                image: <IcnLock />,
            },
            {
                text: 'Starker Schutz deiner persönlichen Daten',

                image: <IcnShield />,
            },
            {
                text: 'Maximale Anonymität',

                image: <IcnMask />,
            },
            {
                text: 'Erstklassige Suchergebnisse',

                image: <IcnStar />,
            },
        ],
    },
    nl: {
        title: 'Directe privacy',
        description: 'Het ultieme mobiele commandocentrum voor privé en veilige verkenning.',
        talkingPoints: [
            {
                text: 'Directe privacy standaard ingeschakeld',

                image: <IcnLock />,
            },
            {
                text: 'Sterke bescherming van persoonlijke gegevens',

                image: <IcnShield />,
            },
            {
                text: 'Maximale anonimiteit',

                image: <IcnMask />,
            },
            {
                text: 'Eersteklas zoekresultaten',

                image: <IcnStar />,
            },
        ],
    },
}

const Section1 = ({languageCode}) => {
    const text = translations[languageCode]
    return (
        <div
            className="section1"
            id="section1"
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 4rem 2rem;
                @media (max-width: 900px) {
                    padding: 4rem 1rem;
                }
                h2,
                p,
                span {
                    color: ${colors.black};
                }
                h2 {
                    text-align: center;
                    font-size: 40px;
                    font-weight: 800;
                    @media (max-width: 900px) {
                        font-size: 32px;
                    }
                }
                p {
                    text-align: center;
                    margin: 10px auto 40px;
                }
                span {
                    line-height: 22px;
                }
            `}
        >
            <div
                className="inner-container"
                css={css`
                    max-width: 1000px;
                    margin: 0 auto;
                `}
            >
                <h2>{text.title}</h2>
                <p>{text.description}</p>
                <div
                    className="side-by-side"
                    css={css`
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-gap: 24px;
                        @media (max-width: 900px) {
                            grid-gap: 16px;
                            grid-template-columns: 1fr 1fr;
                        }
                    `}
                >
                    {text.talkingPoints.map((point, index) => {
                        return (
                            <div
                                key={index}
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    background: ${colors.brandBlueLightest};
                                    padding: 30px 45px;
                                    border-radius: 8px;
                                    max-width: 224px;
                                    .img {
                                        width: 60px;
                                    }
                                    @media (max-width: 900px) {
                                        max-width: 100%;
                                        padding: 17px;
                                    }
                                `}
                            >
                                <div
                                    className="img"
                                    css={css`
                                        svg {
                                            margin: 0 0 12px;
                                            height: 56px;
                                            width: 56px;
                                        }
                                    `}
                                >
                                    {point.image}
                                </div>
                                <span
                                    className="talking-point-text"
                                    css={css`
                                        max-width: 130px;
                                    `}
                                >
                                    {point.text}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Section1
